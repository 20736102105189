import React from "react";
import SponsorsContainer from "./SponsorsContainer";
import SponsorWrapper from "./SponsorWrapper";
import Sponsor from "./Sponsor";
import sponsor2 from "./img/concello-pontevedra.svg";
import sponsor3 from "./img/depo.png";
import sponsor4 from "./img/froiz.jpg";
// import sponsor5 from "./img/nutrisport.png";
import sponsor5 from "./img/nutridos.png";
import sponsor7 from "./img/gesmagal.png";
import sponsor9 from "./img/clesa.png";
import sponsor10 from "./img/caetano.png";
//import sponsor15 from "./img/spiuk.png";
// import sponsor16 from "./img/towcar.png";
import sponsor17 from "./img/xeve.png";
import sponsor18 from "./img/xsauce.png";
//import sponsor19 from "./img/ottobosley.png";
import sponsor21 from "./img/mocaypascual.png";
import sponsor22 from "./img/xunta.jpeg";
//import sponsor23 from "./img/xacobeo2021.jpg";
import sponsor25 from "./img/nogal.png";
import sponsor24 from "./img/pizzeria_carlos.png";
// import sponsor25 from "./img/aguas_parano.jpg";
import sponsor26 from "./img/parasanitaria.jpg";
// import sponsor27 from "./img/sanmarcos.jpg";
import sponsor28 from "./img/dorita.png";
//import sponsor29 from "./img/artio.png";
//import sponsor30 from "./img/galifresh.jpeg";
import sponsor31 from "./img/maglia.png";


class Sponsors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [
      sponsor2,
      sponsor3,
      sponsor22,
      sponsor4,
      sponsor5,
      sponsor9,
      sponsor18,
      sponsor21,
      sponsor25,
      sponsor7,
      sponsor10,
      sponsor17,
      sponsor24,
      sponsor26,
      sponsor28,
      sponsor31,
      ],
      currentIndex: 8,
      interval: null
    };
  }

  componentDidMount = () => {
    const interval = window.setInterval(() => {
      this.goToNextSponsor();
    }, 2000);
    this.setState({ interval });
  };

  goToNextSponsor = () => {
    // if (this.state.currentIndex === this.state.images.length - 1) {
    //   return this.setState({
    //     currentIndex: 0,
    //     translateValue: 0
    //   })
    // }

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -this.sponsorWidth(),
      images: [...prevState.images.slice(1), prevState.images[0]]
    }));
  };

  sponsorWidth = () => {
    return document.querySelector(".sponsor-wrapper").clientWidth;
  };

  render() {
    return (
      <SponsorsContainer>
        {this.state.images.map((image, i) => (
          <SponsorWrapper
            key={i}
            className="sponsor-wrapper"
            style={{
              transform: `translateX(${this.state.translateValue}px)`
            }}
          >
            <Sponsor src={image} />
          </SponsorWrapper>
        ))}
      </SponsorsContainer>
    );
  }
}

export default Sponsors;
