import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import Image2 from "./image2.jpg";
import "./RatesPage.css";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";
import { Link } from "react-router-dom";

class RatesPage extends React.Component {
  render() {
    return (
      <div className="Rates">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Rates-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Rates-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                    APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Rates-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Rates-title">
                  Tarifas
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Rates-info-article">
                  {/*                   <p className="Rates-info-text text-justify">
                    Las inscripciones se abrirán en la página web el{" "}
                    <strong>1 de diciembre</strong> para aquellos
                    participantes que{" "}
                    <strong>realizaron la prueba en la edición anterior</strong>
                    .
                  </p> */}
                  <p className="Rates-info-text text-justify">
                    Las inscripciones se abrirán en la página web el <strong>Viernes 20 de diciembre.</strong>
                    El <strong>cierre de inscripciones será el domingo 20 de abril</strong> (con opción a camiseta
                    conmemorativa) o el <strong>martes 1 de Mayo</strong> (sin opción a camiseta) o hasta completar el
                    aforo máximo de 1500 participantes en todas las categorías de BTT.
                  </p>
                  <h6>TARIFAS 4 PICOS BIKE</h6>
                  <table className="table table-responsive">
                    <tbody>
                    <tr>
                      <th className="scope">Del 20 de diciembre de 2024 al 30 de enero de 2025 o hasta completar los
                        primeras 800 plazas
                      </th>
                      <td>37€</td>
                    </tr>
                    <tr>
                      <th className="scope">Del 1 de febrero de 2025 hasta el 20 de abril de 2025 (con opción a
                        camiseta) o hasta completar las 1500 plazas
                      </th>
                      <td>45€</td>
                    </tr>
                    <tr>
                      <th className="scope">
                        Del 21 de abril de 2025 hasta el 1 de mayo de 2025 (sin opción a camiseta) o hasta completar las
                        1500 plazas
                      </th>
                      <td>45€</td>
                    </tr>
                    </tbody>
                  </table>
                  <h6>TARIFAS PRUEBAS COMBINADAS</h6>
                  <table className="table table-responsive">
                    <tbody>
                    <tr>
                      <th className="scope">Desde 20 de Diciembre de 2024 hasta el 31 de enero de 2025 o hasta completar los primeras 800 plazas
                      </th>
                      <td>69€</td>
                    </tr>
                    <tr>
                      <th className="scope">Desde el 1 de febrero de 2025 hasta el 20 de abril (con opción a camiseta) o completar las 1500 plazas
                      </th>
                      <td>82€</td>
                    </tr>
                    <tr>
                      <th className="scope">
                        Desde el 21 de abril de 2025, hasta el 1 de mayo (sin opción a camiseta) o completar las 1500 plazas
                      </th>
                      <td>82€</td>
                    </tr>
                    </tbody>
                  </table>
                  <p className="small">
                    ***la tarifa se incrementa 4€ en caso de elegir la opción
                    de{" "}
                    <Link to="/seguro-devolucion">“SEGURO DE DEVOLUCIÓN”</Link>{" "}
                    opcional. 7 € en caso de pruebas combinadas.
                  </p>
                  <p className="small">
                    ***la tarifa se incrementa <u>35€</u> en caso de querer el <u>maillot, o el
                    chaleco</u> conmemorativo, <u>39€</u> en caso de querer el <u>maillot de manga larga, 29€</u> en
                    caso de querer la <u>sudadera oficial de la prueba y 55€</u> en el caso del culotte <b>(la fecha
                    tope para solicitar estos artículos será el 14 de Abril de 2025)</b>.
                  </p>
                  <p className="Rates-info-text text-justify">
                    <strong>
                      Todo participante inscrito para poder obtener la devolución de su inscripción deberá marcar en la
                      ficha de inscripción la opción “SEGURO DE DEVOLUCIÓN”. Aquellos participantes que no se acojan a
                      este seguro, no tendrán derecho a la devolución de su inscripción en ninguna de las
                      circunstancias.
                    </strong>
                  </p>
                </article>
              </div>
              <div className="col-md-12 col-lg-6">
                <div
                  className="Rates-info-img"
                  style={{
                    backgroundImage: `url(${Image2})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    backgroundSize: "cover",
                    height: "800px",
                    marginRight: "-15px",
                    marginLeft: "-15px"
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
        <Media query={{minWidth: 768}}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default RatesPage;
